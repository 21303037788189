<template>
  <div class="article">
    <div class="step1">
      <div class="title">
        <h5><span>1</span>수주내역 검색</h5>
      </div>
      <div class="cont">
        <span>납품 요청일</span>
        <div class="input_text" v-if="showSearchDate">
          <input
            type="date"
            placeholder="YYYY-MM-DD"
            :value="start_date"
            @change="setStartDate($event)"
            v-if="showSearchDate"
          />~
        </div>
        <div class="input_text" v-if="showSearchDate">
          <input
            type="date"
            placeholder="YYYY-MM-DD"
            :value="end_date"
            @change="setEndDate($event)"
            v-if="showSearchDate"
          />
        </div>
        <button class="btn_sub1 refresh_btn" @click="refreshData">
          재조회
        </button>
      </div>
    </div>
    <div class="step2">
      <div class="title">
        <h5><span>2</span>수주 항목 내 세부제품 추출</h5>
      </div>
      <div class="cont">
        <h6>
          조회수 :
          {{ filteredSalesList.length }}건
        </h6>
        <div class="mes_tbl_wrap">
          <table class="mes_tbl">
            <colgroup>
              <col v-for="(n, index) in 4" :key="index" />
            </colgroup>
            <thead>
              <tr>
                <th>매출처</th>
                <th>납품제품</th>
                <th>납품 요청일</th>
                <th>전체 제품 추출</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(sale, index) in filteredSalesList" :key="sale.id">
                <td>{{ getCompanyInfo(sale.company_id).name || '' }}</td>
                <td>{{ getFirstSalesProduct(index) }}</td>
                <td>{{ sale.delivery_date }}</td>
                <td>
                  <button
                    class="btn_tbl"
                    @click="
                      selected_product_list.find(x => x.sales_id == sale.id) ==
                      undefined
                        ? pushProductsToList(index)
                        : deleteProductsFromList(sale.id)
                    "
                    :class="{
                      disabled:
                        selected_product_list.find(
                          x => x.sales_id == sale.id,
                        ) != undefined,
                    }"
                  >
                    {{
                      selected_product_list.find(x => x.sales_id == sale.id) !=
                      undefined
                        ? '추출값 반환'
                        : '제품 추출'
                    }}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="step3">
      <div class="title">
        <h5><span>3</span>세부제품 내 필요 원자재 추출</h5>
      </div>
      <div class="cont">
        <div class="mes_tbl_wrap">
          <table class="mes_tbl">
            <colgroup>
              <col v-for="(n, index) in 5" :key="index" />
            </colgroup>
            <thead>
              <tr>
                <th>매출처</th>
                <th>제품명</th>
                <th>규격</th>
                <th>수량(단위)</th>
                <th>필요 원자재 추출</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(product, index) in selected_product_list"
                :key="product.id"
              >
                <td class="text_left">
                  {{
                    getCompanyInfo(
                      sales.find(x => x.id == product.sales_id).company_id,
                    ).name
                  }}
                </td>
                <td class="text_left">
                  {{
                    product.parent_product_id != null
                      ? getProductInfo(product.parent_product_id).name + ' ▶ '
                      : ''
                  }}
                  {{
                    product.product_label != undefined
                      ? product.product_label
                      : getProductInfo(product.product_id).name
                  }}
                </td>
                <td class="text_left">
                  {{ getProductInfo(product.product_id).standard }}
                </td>
                <td
                  class="text_right"
                  v-if="product.quantity_label != undefined"
                >
                  <b>{{ product.quantity_label.split(' ')[0] }}</b>
                  {{ product.quantity_label.split(' ')[1] }}
                </td>
                <td class="text_right" v-else>
                  {{
                    product.box_unit_id == null
                      ? ``
                      : `${$makeComma(product.quantity)} x ${getBoxName(
                          product.box_unit_id,
                        )} =
                  `
                  }}
                  <b>{{
                    product.box_unit_id == null
                      ? `${$makeComma(product.quantity)}`
                      : `${$makeComma(
                          $decimalMul(
                            product.quantity,
                            getBoxQuantity(product.box_unit_id),
                          ),
                        )}`
                  }}</b>
                  {{ `(${getUnitName(product.unit_id)})` }}
                </td>
                <td>
                  <button
                    @click="
                      selected_material_list.find(
                        x => x.bom_parents_id == product.bom_parents_id,
                      ) != undefined
                        ? boms.find(x => x.product_id == product.product_id) ==
                            undefined &&
                          product_groups.filter(
                            x => x.member_product_id == product.product_id,
                          ).length == 0
                          ? pushMaterialToList(index)
                          : deleteMaterialFromList(product.bom_parents_id)
                        : pushMaterialToList(index)
                    "
                    class="btn_tbl"
                    :class="{
                      disabled:
                        chkMaterial(product) ||
                        (selected_material_list.find(
                          x => x.bom_parents_id == product.bom_parents_id,
                        ) != undefined &&
                          (boms.find(x => x.product_id == product.product_id) !=
                            undefined ||
                            product_groups.filter(
                              x => x.member_product_id == product.product_id,
                            ).length > 0)),
                    }"
                    :disabled="chkMaterial(product)"
                  >
                    {{
                      chkMaterial(product)
                        ? '자재 추출'
                        : selected_material_list.find(
                            x => x.bom_parents_id == product.bom_parents_id,
                          ) != undefined
                        ? boms.find(x => x.product_id == product.product_id) ==
                            undefined &&
                          product_groups.filter(
                            x => x.member_product_id == product.product_id,
                          ).length == 0
                          ? '자재 추출'
                          : '추출값 반환'
                        : '자재 추출'
                    }}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="step4">
      <div class="title">
        <h5><span>4</span>필요 원자재 발주</h5>
      </div>
      <div class="cont">
        <div class="mes_tbl_wrap">
          <table class="mes_tbl">
            <colgroup
              :class="{ group_material_chk: isIncludeGroupMaterialOnList }"
            >
              <col v-for="(n, index) in 7" :key="index" />
            </colgroup>
            <thead>
              <tr>
                <th colspan="2">
                  원자재명
                </th>
                <th>구매처</th>
                <th>사용단위</th>
                <th>필요수량</th>
                <th>재고수량</th>
                <th>필요 발주량 (안전재고+필요수량)</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(material, index) in group_material_list" :key="index">
                <td
                  class="text_left"
                  :class="{
                    group_td:
                      isIncludeGroupMaterialOnList &&
                      findInfoFromId(materials, material.material_id)
                        .material_group_yn == 1,
                  }"
                  :colspan="
                    isIncludeGroupMaterialOnList &&
                    findInfoFromId(materials, material.material_id)
                      .material_group_yn
                      ? 1
                      : 2
                  "
                  :rowspan="
                    group_material_list.filter(
                      x => x.parents_id == material.material_id,
                    ).length + 1
                  "
                  v-if="!material.member_yn"
                >
                  {{ getMaterialInfo(material.material_id).name
                  }}<br
                    v-show="
                      findInfoFromId(materials, material.material_id)
                        .material_group_yn
                    "
                  />{{
                    (getMaterialInfo(material.material_id).standard.trim()
                      .length == 0
                      ? ''
                      : ' - ') + getMaterialInfo(material.material_id).standard
                  }}
                </td>
                <td
                  class="group_option"
                  v-if="
                    isIncludeGroupMaterialOnList &&
                      (findInfoFromId(materials, material.material_id)
                        .material_group_yn == true ||
                        material.member_yn == true)
                  "
                >
                  {{
                    material.member_yn
                      ? findInfoFromId(materials, material.material_id).name +
                        (findInfoFromId(
                          materials,
                          material.material_id,
                        ).standard.trim().length == 0
                          ? ''
                          : ' - ') +
                        findInfoFromId(materials, material.material_id).standard
                      : ''
                  }}
                  <button
                    v-if="
                      findInfoFromId(materials, material.material_id)
                        .material_group_yn && isPermissionOn
                    "
                    @click="
                      include_list = findInfoFromId(
                        materials,
                        material.material_id,
                      ).material_group_list;
                      ignore_list = group_material_list
                        .filter(x => x.parents_id == material.material_id)
                        .map(x => {
                          return {
                            id: x.material_id,
                            resource_type_id: findInfoFromId(
                              materials,
                              x.material_id,
                            ).resource_type_id,
                          };
                        });
                      selected_group_material = material;
                      selected_group_material_index = index;
                      showMaterialSearch = true;
                    "
                  >
                    <img src="@/assets/images/icon/icon-floating-search.png" />
                  </button>
                  <button
                    class="floating_delete"
                    v-if="material.member_yn"
                    @click="group_material_list.splice(index, 1)"
                  >
                    <img src="@/assets/images/icon/icon-floating-delete.png" />
                  </button>
                </td>
                <td
                  class="text_left"
                  :class="{
                    not_group:
                      !findInfoFromId(materials, material.material_id)
                        .material_group_yn &&
                      material.order_company == -1 &&
                      $makeNumber(material.order_quantity) > 0,
                  }"
                  v-if="
                    !findInfoFromId(materials, material.material_id)
                      .material_group_yn && isPermissionOn
                  "
                >
                  {{ getCompanyInfo(material.order_company).name
                  }}<span
                    class="ico_search"
                    @click="
                      {
                        ignore_company_list = [];
                        if (material.material_companys.length < 1) {
                          ignore_company_list = undefined;
                        } else {
                          material.material_companys.forEach(el => {
                            ignore_company_list.push(el.company_id);
                          });
                        }
                        clicked_index = index;
                        showCompanySearch = true;
                      }
                    "
                  ></span>
                </td>
                <td v-else></td>
                <td>
                  {{
                    `${getUnitName(
                      getMaterialInfo(material.material_id).using_unit_id,
                    )}`
                  }}
                </td>
                <td class="text_right" v-if="!material.member_yn">
                  <b>{{ `${$makeComma(material.need_quantity)}` }}</b>
                  {{
                    `(${getUnitName(
                      getMaterialInfo(material.material_id).using_unit_id,
                    )})`
                  }}
                </td>
                <td class="text_right" v-else>
                  <b>{{
                    `${$makeComma(getGroupMaterialNeedQuantity(material))}`
                  }}</b>
                  {{
                    `(${getUnitName(
                      getMaterialInfo(material.material_id).using_unit_id,
                    )})`
                  }}
                </td>
                <td
                  class="text_right"
                  v-if="
                    !findInfoFromId(materials, material.material_id)
                      .material_group_yn
                  "
                >
                  <b>
                    {{
                      `${$makeComma(getMaterialStock(material.material_id))}`
                    }} </b
                  >{{
                    ` (${getUnitName(
                      getMaterialInfo(material.material_id).incoming_unit_id,
                    )})`
                  }}
                </td>
                <!-- 총 그룹 재고 -->
                <td v-else class="text_right">
                  <b>
                    {{
                      `${$makeComma(
                        group_material_list
                          .filter(x => x.parents_id == material.material_id)
                          .map(x => getMaterialStock(x.material_id))
                          .reduce((a, b) => $decimalAdd(a, b), 0),
                      )}`
                    }} </b
                  >{{
                    ` (${getUnitName(
                      getMaterialInfo(material.material_id).incoming_unit_id,
                    )})`
                  }}
                </td>
                <td
                  v-if="
                    !findInfoFromId(materials, material.material_id)
                      .material_group_yn
                  "
                >
                  <input
                    type="text"
                    placeholder="수량 입력"
                    inputmode="decimal"
                    @input="$inputNumber($event, material, 'order_quantity')"
                    :value="$makeComma(material.order_quantity)"
                    :disabled="!isPermissionOn"
                  />
                  <label>
                    {{
                      ` ${getUnitName(
                        getMaterialInfo(material.material_id).incoming_unit_id,
                      )}`
                    }}</label
                  >
                </td>
                <td v-else></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="btn_wrap">
      <button
        class="btn_sub2"
        @click="checkModal"
        v-if="isPermissionOn"
        :disabled="isValidModify"
      >
        원자재 발주
      </button>
    </div>
    <company-search
      v-if="showCompanySearch"
      :search_type="2"
      :ignore_company_list="ignore_company_list"
      @onclose="showCompanySearch = false"
      @onselect="chageOrderCompany($event)"
    ></company-search>
    <resource-search
      v-if="showMaterialSearch"
      :filter_type="1"
      :include_list="filtered_include_list"
      :ignore_list="ignore_list"
      @onclose="showMaterialSearch = false"
      @onselect="
        $event => {
          insertMemberMaterial($event);
          showMaterialSearch = false;
        }
      "
    ></resource-search>
    <two-button-modal
      :modal_title="my_modal_title"
      :modal_content="my_modal_content"
      :isModalOpen="my_isModalOpen"
      :index="-1"
      @onclose="my_isModalOpen = false"
      @OnYesClick="
        () => {
          goToBom();
          my_isModalOpen = false;
        }
      "
    ></two-button-modal>
    <two-button-modal2
      :modal_title="'발주량 부족'"
      :modal_content="warning_text"
      :isModalOpen="warning_show"
      @onclose="warning_show = false"
      @OnYesClick="
        () => {
          submitForm();
          warning_show = false;
        }
      "
    ></two-button-modal2>
    <div class="modal+_"></div>
  </div>
</template>

<script>
import FetchMixin from '@/mixins/fetch';
import FavoriteMixin from '@/mixins/favorite';
import ModalMixin from '@/mixins/modal';
import SpinnerMixin from '@/mixins/spinner';
import routes from '@/routes/routes';
import { mapGetters } from 'vuex';
import { yyyymmdd } from '@/utils/func';
import CompanySearch from '@/layouts/components/search-popup/CompanySearch';
import ResourceSearch from '@/layouts/components/search-popup/ResourceSearch';
import PriceMixin from '@/mixins/price';
import TwoButtonModal from '@/layouts/components/TwoButtonModal';
import TwoButtonModal2 from '@/layouts/components/TwoButtonModal';
export default {
  mixins: [FetchMixin, ModalMixin, SpinnerMixin, PriceMixin, FavoriteMixin],
  components: {
    CompanySearch,
    TwoButtonModal,
    ResourceSearch,
    TwoButtonModal2,
  },
  data() {
    return {
      showSearchDate: false,
      bom_parents_id_count: -10000,
      showCompanySearch: false,
      clicked_index: -1,
      ignore_company_list: [],

      showMaterialSearch: false,
      include_list: [],
      ignore_list: [],
      selected_group_material: null,
      selected_group_material_index: null,

      //modal flag
      my_modal_title: '',
      my_modal_content: '',
      my_modal_index: -1,
      my_isModalOpen: false,
      modal_product_id_temp: null,

      //two button modal 2
      warning_text: '',
      warning_show: false,
    };
  },
  computed: {
    ...mapGetters({
      date: 'getDateFromCalculation',
      start_date: 'getStartDateFromCalculation',
      end_date: 'getEndDateFromCalculation',
      selected_product_list: 'getSelectedProductListFromCalculation',
      selected_material_list: 'getSelectedMaterialListFromCalculation',
      sales: 'getSalesFromCalculation',
      companys: 'getCompany',
      products: 'getProduct',
      boxs: 'getBoxsNotSorted',
      units: 'getUnitCodes',
      boms: 'getBom',
      materials: 'getMaterial',
      material_stock: 'getMaterialTotalStock',
      material_occupied_stock: 'getMaterialOccupiedStock',
      material_companys: 'getMaterialCompany',
      product_groups: 'getProductGroup',
      material_groups: 'getMaterialGroup',
      unit_conversions: 'getUnitConversion',
      group_material_list: 'getGroupMaterialListFromCalculation',
    }),
    checkEnoughOrder() {
      const material_list = this.lodash
        .clonedeep(this.group_material_list)
        .filter(
          x =>
            !x.member_yn &&
            this.material_groups.find(
              y => y.group_material_id == x.material_id,
            ) == undefined &&
            this.$makeNumber(x.order_quantity) <
              this.$makeNumber(x.order_quantity_temp),
        );

      let group_material_list = this.lodash
        .clonedeep(this.group_material_list)
        .filter(
          x =>
            this.material_groups.find(
              y => y.group_material_id == x.material_id,
            ) != undefined,
        );

      group_material_list.map(y => {
        const memberMaterials = this.lodash
          .clonedeep(this.group_material_list)
          .filter(x => x.parents_id == y.material_id);
        let remainQuantity = this.$decimalSub(
          this.$makeNumber(y.need_quantity),
          this.lodash
            .clonedeep(memberMaterials)
            .map(x => this.getMaterialStock(x.material_id))
            .reduce(
              (a, b) =>
                this.$decimalAdd(this.$makeNumber(a), this.$makeNumber(b)),
              0,
            ),
        );
        remainQuantity = remainQuantity < 0 ? 0 : remainQuantity;
        const sumMemberMaterialsOrderQuantity = this.lodash
          .clonedeep(memberMaterials)
          .map(z => z.order_quantity)
          .reduce(
            (a, b) =>
              this.$decimalAdd(this.$makeNumber(a), this.$makeNumber(b)),
            0,
          );
        y.memberMaterials = memberMaterials;
        y.remainQuantity = remainQuantity;
        y.sumMemberMaterialsOrderQuantity = sumMemberMaterialsOrderQuantity;
        return y;
      });
      group_material_list = group_material_list.filter(
        x => x.remainQuantity > x.sumMemberMaterialsOrderQuantity,
      );
      return {
        material: material_list,
        group_material: group_material_list,
      };
    },
    isValidModify() {
      let orderListLen = this.group_material_list.length;

      for (let i = 0; i < orderListLen; i++) {
        if (this.group_material_list[i].order_quantity != 0) {
          return false;
        }
      }
      return true;
    },
    filtered_include_list() {
      const listTemp = this.lodash.clonedeep(this.include_list);
      listTemp.forEach(element => {
        element.id = element.member_material_id;
      });
      return listTemp;
    },
    isIncludeGroupMaterialOnList() {
      if (this.group_material_list.length < 1) {
        return false;
      } else {
        const filtered = this.group_material_list.filter(
          x =>
            this.findInfoFromId(this.materials, x.material_id)
              .material_group_yn,
        );
        if (filtered.length > 0) {
          return true;
        } else {
          return false;
        }
      }
    },
    filteredSalesList() {
      if (this.sales.length > 0) {
        const salesList = this.lodash
          .clonedeep(this.sales)
          .filter(x => x.sales_type_id != 2)
          .filter(
            x =>
              x.product_list.filter(
                y =>
                  !y.order_yn &&
                  !(
                    this.getProductInfo(y.product_id).product_group_yn &&
                    this.getProductInfo(y.product_id).product_group_type_id == 2
                  ),
              ).length > 0,
          )
          .sort(
            (a, b) =>
              new Date(b.delivery_date) - new Date(a.delivery_date) ||
              b.id - a.id,
          );

        return salesList;
      } else return [];
    },
    checkValid() {
      if (
        this.group_material_list != undefined &&
        this.group_material_list.length > 0
      ) {
        const company_check = this.group_material_list.filter(
          x =>
            x.order_company == -1 &&
            !this.findInfoFromId(this.materials, x.material_id)
              .material_group_yn &&
            this.$makeNumber(x.order_quantity) > 0,
        );
        if (company_check.length > 0) {
          return 2;
        } else {
          return 1;
        }
      } else {
        return 0;
      }
    },
  },
  methods: {
    checkModal() {
      if (
        this.checkEnoughOrder.material.length > 0 ||
        this.checkEnoughOrder.group_material.length > 0
      ) {
        this.warning_text = '';
        this.checkEnoughOrder.material.forEach(element => {
          this.warning_text +=
            this.findInfoFromId(this.materials, element.material_id).name +
            ' - ' +
            this.findInfoFromId(this.materials, element.material_id).standard +
            ' <br>';
        });
        this.checkEnoughOrder.group_material.forEach(element => {
          this.warning_text +=
            this.findInfoFromId(this.materials, element.material_id).name +
            ' - ' +
            this.findInfoFromId(this.materials, element.material_id).standard +
            ' <br>';
        });

        this.warning_text +=
          '=======================<br><br>입력된 발주량이 필요 발주량보다 적습니다.<br>그래도 발주 하시겠습니까?';
        this.warning_show = true;
      } else {
        this.submitForm();
      }
    },
    getGroupMaterialNeedQuantity(material) {
      const needQuantity = this.$decimalSub(
        this.getParentsNeedQuantity(material),
        this.getGroupMaterialStockAll(material),
      );
      return needQuantity > 0 ? needQuantity : 0;
    },
    getGroupMaterialStockAll(material) {
      return this.group_material_list
        .filter(x => x.parents_id == material.parents_id)
        .map(x => this.getMaterialStock(x.material_id))
        .reduce((a, b) => this.$decimalAdd(a, b), 0);
    },
    getParentsNeedQuantity(material) {
      return this.group_material_list.find(
        x => x.material_id == material.parents_id,
      ).need_quantity;
    },
    insertMemberMaterial(member) {
      const group = this.lodash.clonedeep(this.selected_group_material);
      console.log('group', group);
      console.log('member', member);
      group.parents_id = this.lodash.clonedeep(group.material_id);
      group.material_id = member.id;
      group.material_companys = member.company_list;
      group.order_company =
        member.company_list.length > 0 ? member.company_list[0].company_id : -1;
      group.name = member.name;
      group.order_quantity = 0;
      group.id += Math.random() * (100000 - 1000) + 1000;
      group.member_yn = true;
      const cloneGroup = this.group_material_list;
      cloneGroup.splice(this.selected_group_material_index + 1, 0, group);
    },
    goToBom() {
      const goingBomPage = routes[0].children.find(x => x.path == '/code/bom');
      console.log('going page ', goingBomPage);
      if (goingBomPage != undefined) {
        goingBomPage.meta.select = this.lodash.clonedeep(
          this.modal_product_id_temp,
        );
        this.$router.push('/code/bom');
      }
      // products 리스트에서 modal_product_id_temp 가지고 PRODUCT object 찾아서
      // bom 페이지로 넘기기
      //SetOpenTabIndex(1)
      // bom 페이지에서 등록하는 서브 메뉴로 바꾸기  ( SetOpenTabIndex(1)) //bomPage
      // 등록 폼에서 product{object} 넘긴거로 method 호출시키기 // bomNewFOrm
      // selectProduct(product{object})
    },
    async submitForm() {
      if (this.checkValid == 1) {
        let data = this.lodash.clonedeep(this.group_material_list);
        data = data.filter(x => x.order_company != -1);
        data = data.filter(x => this.$makeNumber(x.order_quantity) > 0);
        var input_date = new Date();
        var date2 = new Date();
        var incoming_date = new Date(date2.setDate(date2.getDate() + 7));
        // let insertedCompany = [];
        // let data_list = [];
        let iden_count = 1;
        // let ordered_materials = [];
        const obj = data.reduce(function(rv, x) {
          (rv[x['order_company']] = rv[x['order_company']] || []).push(x);
          return rv;
        }, {});
        let arr = [];
        for (let k in obj) {
          arr.push({
            input_date: input_date,
            incoming_date: incoming_date,
            detail: `#${yyyymmdd(input_date)} 소요량산출`,
            purchase_ident:
              yyyymmdd(input_date).replace(/-/gi, '') + iden_count,
            purchase_type_id: 4,
            company_id: k,
            completed: false,
            vat_id: this.getCompanyInfo(k).vat,
            purchase_materials: obj[k].map(x => {
              const mat_info = this.getMaterialInfo(x.material_id);
              return {
                material_id: x.material_id,
                quantity: this.$makeNumber(x.order_quantity),
                standard: mat_info.standard,
                cost: 0,
                total_cost: 0,
                unit_id: mat_info.incoming_unit_id,
                supply_value: 0,
                tax: 0,
                tax_id: mat_info.tax,
                input_yn: false,
              };
            }),
            //
            count: obj[k].length,
          });
        }
        console.log('log1', arr);

        // data.forEach(purchase => {
        //   if (!insertedCompany.includes(purchase.order_company)) {
        //     const payload = {
        //       input_date: input_date,
        //       incoming_date: incoming_date,
        //       detail: `#${yyyymmdd(input_date)} 소요량산출`,
        //       purchase_ident:
        //         yyyymmdd(input_date).replace(/-/gi, '') + iden_count,
        //       purchase_type_id: 4,
        //       company_id: purchase.order_company,
        //       completed: false,
        //       vat_id: this.getCompanyInfo(purchase.order_company).vat,
        //       purchase_materials: [],
        //     };
        //     const purchase_materials_temp = data.filter(
        //       x => x.order_company == purchase.order_company,
        //     );

        //     purchase_materials_temp.forEach(el => {
        //       const mat_info = this.getMaterialInfo(el.material_id);

        //       payload.purchase_materials.push({
        //         material_id: el.material_id,
        //         quantity: this.$makeNumber(el.order_quantity),
        //         standard: mat_info.standard,
        //         cost: 0,
        //         total_cost: 0,
        //         unit_id: mat_info.using_unit_id,
        //         supply_value: 0,
        //         tax: 0,
        //         tax_id: mat_info.tax,
        //         input_yn: false,
        //       });
        //     });
        //     data_list.push(payload);
        //     insertedCompany.push(purchase.order_company);
        //   }
        // });

        //추출된 제품 리스트 중 수주제품만을 필터링(반제품 제거)
        const sales_products = this.selected_product_list.filter(x => x.id > 0);
        //필터링 된 제품리스트 중 자재가 추출된 제품만을 필터링(제품추출 해두고 자재추출안한것 제거)
        const update_products = this.lodash.clonedeep(
          sales_products.filter(
            x =>
              this.selected_material_list.find(y => y.bom_parents_id == x.id) !=
              undefined,
          ),
        );
        update_products.forEach(el => {
          el.order_yn = true;
        });
        // console.log('log2', data_list);
        // return;
        await this.showSpinner();
        this.$store
          .dispatch('INSERT_PURCHASE_ALL', { data_list: arr })
          .then(async () => {
            this.$store
              .dispatch('UPDATE_SALES_PRODUCT_LIST', {
                data_list: update_products,
              })
              .then(async () => {
                this.openOneButtonModal(
                  '임시 발주 등록 성공',
                  '필요 원자재의 임시발주에 성공했습니다.',
                );
                this.$store.commit('setGroupMaterialListToCalculation', []);
                this.$store.commit('setSelectedMaterialListToCalculation', []);
                this.$store.commit('setSelectedProductListToCalculation', []);
                await this.FETCH_SALES();
              });
          })
          .catch(() => {
            this.openOneButtonModal(
              '등록 실패',
              '임시발주 등록에 실패하였습니다. ',
            );
          })
          .finally(() => {
            this.hideSpinner();
          });
      } else if (this.checkValid == 2) {
        this.openOneButtonModal(
          '발주 불가',
          '발주할 모든 자재의 구매처를 선택하여 주십시오',
        );
      } else {
        this.openOneButtonModal(
          '발주 불가',
          '하나 이상 자재를 발주하여주십시오.',
        );
      }
    },

    chageOrderCompany(arg) {
      this.group_material_list[this.clicked_index].order_company = arg.id;
      this.showCompanySearch = false;
    },
    groupMaterials() {
      const obj = this.lodash.clonedeep(
        this.selected_material_list.reduce(function(rv, x) {
          (rv[x['material_id']] = rv[x['material_id']] || []).push(x);
          return rv;
        }, {}),
      );
      let arr = [];

      for (let k in obj) {
        let sumQuantity = 0;

        const order_unit =
          this.getMaterialInfo(obj[k][0].material_id).order_unit || 0;
        const stock_size =
          this.getMaterialInfo(obj[k][0].material_id).stock_size || 0;
        obj[k].forEach(element => {
          sumQuantity = this.$decimalAdd(sumQuantity, element.need_quantity);
        });
        obj[k][0].need_quantity = this.$makeNumber(sumQuantity);

        obj[k][0].order_quantity = this.$decimalSub(
          this.$decimalMul(
            this.$makeNumber(obj[k][0].need_quantity),
            this.$makeNumber(obj[k][0].cvr_ratio),
          ),
          this.lodash.clonedeep(this.getMaterialStock(obj[k][0].material_id)),
        );
        const useQuantity = this.$decimalMul(
          this.$makeNumber(obj[k][0].need_quantity),
          this.$makeNumber(obj[k][0].cvr_ratio),
        );
        console.log('사용량', useQuantity);
        console.log('현재고', this.getMaterialStock(obj[k][0].material_id));
        console.log('발주단위', order_unit);
        console.log('안전재고', stock_size);
        let goalQuantity = this.$decimalSub(
          this.$decimalSub(
            this.getMaterialStock(obj[k][0].material_id),
            stock_size,
          ),
          useQuantity,
        );

        console.log('현재고 - 안전재고 - 사용량 = ', goalQuantity);
        if (goalQuantity >= 0) {
          //발주 불필요
          obj[k][0].order_quantity = 0;
        } else {
          //발주 필요
          if (order_unit > 0) {
            const share = Math.floor(
              -1 * this.$decimalDiv(goalQuantity, order_unit),
            );
            const rest = this.$decimalMod(goalQuantity, order_unit);

            if (rest < 0) {
              obj[k][0].order_quantity = this.$decimalMul(
                order_unit,
                share + 1,
              );
            } else {
              obj[k][0].order_quantity = this.$decimalMul(order_unit, share);
            }
          } else {
            //발주단위 없음
            obj[k][0].order_quantity = this.$decimalMul(goalQuantity, -1);
          }
        }

        if (obj[k][0].order_quantity < 0) obj[k][0].order_quantity = 0;
        arr.push(obj[k][0]);
      }
      console.log('@@@@@', arr);
      arr.map(x => {
        x.order_quantity_temp = x.order_quantity;
        return x;
      });
      this.$store.commit(
        // 원자재 set
        'setGroupMaterialListToCalculation',
        this.lodash.clonedeep(arr),
      );
      // this.group_material_list = this.lodash.clonedeep(arr);
    },
    changeOrderQuantity(e, index) {
      this.group_material_list[index].order_quantity = e.target.value;
    },
    pushProductsToList(index) {
      this.filteredSalesList[index].product_list.forEach(el => {
        const parentProduct = this.findInfoFromId(this.products, el.product_id);

        if (
          !el.order_yn &&
          parentProduct.product_group_yn != true &&
          parentProduct.product_group_type_id != 2
        ) {
          el.bom_parents_id = el.id;
          el.total_quantity =
            el.box_unit_id != null
              ? this.getBoxQuantity(el.box_unit_id) * el.quantity
              : el.quantity;
          this.selected_product_list.push(el);
          this.makeBomProduct(el);
        }
      });
    },
    makeBomProduct(el) {
      //bom안에 제품이 또 포함되어 있다면 추가한다.
      const bom = this.boms.find(x => x.product_id == el.product_id);
      if (bom != undefined) {
        // const box_quantity =
        //   el.box_unit_id != null ? this.getBoxQuantity(el.box_unit_id) : null;
        // const el_unit_name = this.getUnitName(el.unit_id);
        // const el_company_name = this.getCompanyInfo(
        //   this.sales.find(x => x.id == el.sales_id).company_id,
        // ).name;
        const productInfo = this.getProductInfo(el.product_id);
        const parentProductChk =
          el.parent_product_id != null
            ? this.getProductInfo(el.parent_product_id).name + ' ▶ '
            : '';
        const el_product_name = parentProductChk + productInfo.name;

        bom.bom_process.forEach(process => {
          process.bom_resource.forEach(product => {
            if (product.product_id != null) {
              const product_unit_name = this.getUnitName(
                this.getProductInfo(product.product_id).stock_unit_id,
              );
              const product_name = this.getProductInfo(product.product_id).name;
              const new_element = {
                box_unit_id: null,
                id: this.bom_parents_id_count,
                product_id: product.product_id,
                quantity: product.quantity,
                total_quantity: this.$decimalMul(
                  product.quantity,
                  el.total_quantity,
                ),
                unit_id: this.getProductInfo(product.product_id).stock_unit_id,
                sales_id: el.sales_id,
                product_label: el_product_name + ' ▶ ' + product_name,
                bom_parents_id: el.bom_parents_id,
                // quantity_label: `${this.$makeComma(
                //   el.total_quantity,
                // )}${el_unit_name} x ${this.$makeComma(
                //   product.quantity,
                // )}${product_unit_name} = ${this.$makeComma(
                //   product.quantity * el.total_quantity,
                // )}${product_unit_name}`,
                quantity_label: `${this.$makeComma(
                  this.$decimalMul(product.quantity, el.total_quantity),
                )} (${product_unit_name})`,
              };
              this.selected_product_list.push(new_element);
              this.bom_parents_id_count--;
              this.makeBomProduct(new_element);
            }
          });
        });
      }
    },
    deleteProductsFromList(id) {
      const mat_temp = this.selected_product_list.filter(x => x.sales_id == id);
      // console.log(mat_temp);
      this.$store.commit(
        'setSelectedProductListToCalculation',
        this.lodash.clonedeep(
          this.selected_product_list.filter(x => x.sales_id != id),
        ),
      );
      if (mat_temp != undefined && mat_temp.length > 0) {
        mat_temp.forEach(el => {
          this.deleteMaterialFromList(el.bom_parents_id);
        });
      }
    },
    pushMaterialToList(index) {
      const selectedRow = this.lodash.clonedeep(
        this.selected_product_list[index],
      );

      let bom = this.boms.find(x => x.product_id == selectedRow.product_id);
      console.log(bom);
      // return;
      if (bom == undefined) {
        const filteredGroup = this.product_groups.filter(
          x => x.member_product_id == selectedRow.product_id,
        );
        if (filteredGroup.length > 0) {
          //여기서 부모 제품 중 하나를 선택 일단은 첫번째꺼로 진행되도록 짜겠음.
          console.log(filteredGroup[0].group_product_id, '@@@@@@@@');
          selectedRow.product_id = filteredGroup[0].group_product_id;
          selectedRow.total_quantity = this.$decimalMul(
            selectedRow.total_quantity,
            filteredGroup[0].quantity,
          );
          bom = this.boms.find(
            x => x.product_id == filteredGroup[0].group_product_id,
          );
          if (bom == undefined) {
            this.modal_product_id_temp = selectedRow.product_id;
            this.my_modal_title = '알림';
            this.my_modal_content = `${
              this.getProductInfo(selectedRow.product_id).name // 테스트 제품
            } 제품은 BOM이 등록되어있지 않습니다! 등록하시겠습니까?`;
            this.my_isModalOpen = true;
            return;
          }
          console.log('newBom', bom);
        } else {
          this.modal_product_id_temp = selectedRow.product_id;
          this.my_modal_title = '알림';
          this.my_modal_content = `${
            this.getProductInfo(selectedRow.product_id).name // 테스트 제품
          } 제품은 BOM이 등록되어있지 않습니다! 등록하시겠습니까?`;
          this.my_isModalOpen = true;
          return;
        }
      }

      bom.bom_process.forEach(process => {
        process.bom_resource.forEach(el => {
          const newElement = this.lodash.clonedeep(el);

          if (newElement.product_id == null) {
            newElement.bom_parents_id = selectedRow.bom_parents_id;
            newElement.product_unique_id = selectedRow.id;
            if (!newElement.percent_yn) {
              newElement.need_quantity = this.lodash.clonedeep(
                Number(
                  this.$decimalMul(
                    newElement.quantity,
                    selectedRow.total_quantity,
                  ),
                ),
              );
            } else {
              const materialInfo = this.getMaterialInfo(newElement.material_id);
              const productInfo = this.getProductInfo(selectedRow.product_id);

              const cvr_ratio = this.unit_conversions.find(
                x =>
                  x.in_unit_id == materialInfo.using_unit_id &&
                  x.out_unit_id == productInfo.stock_unit_id,
              );

              if (cvr_ratio != undefined) {
                newElement.need_quantity = this.$decimalMul(
                  this.$decimalMul(
                    selectedRow.total_quantity,
                    this.$decimalDiv(newElement.quantity, 100),
                  ),
                  cvr_ratio.cvr_ratio,
                );
                // newElement.need_quantity = this.$decimalMul(
                //   this.$decimalMul(
                //     selectedRow.total_quantity,
                //     newElement.quantity,
                //   ),
                //   cvr_ratio.cvr_ratio,
                // );
              } else {
                newElement.need_quantity = this.$decimalMul(
                  selectedRow.total_quantity,
                  this.$decimalDiv(newElement.quantity, 100),
                );
              }
            }
            const companysTemp = this.lodash.clonedeep(this.material_companys);
            newElement.material_companys = companysTemp.filter(
              x => x.material_id == newElement.material_id,
            );
            newElement.order_company =
              newElement.material_companys.length > 0
                ? newElement.material_companys[0].company_id
                : -1;
            const materialInfo = this.getMaterialInfo(newElement.material_id);
            const cvr_ratio = this.unit_conversions.find(
              x =>
                x.in_unit_id == materialInfo.incoming_unit_id &&
                x.out_unit_id == materialInfo.using_unit_id,
            );
            newElement.cvr_ratio =
              cvr_ratio != undefined ? cvr_ratio.cvr_ratio : 1;

            this.selected_material_list.push(newElement);
          } else {
            console.log('product');
          }
        });
      });

      //다른 제품들도 bom검사해서 한번에 추출함.
      this.selected_product_list.forEach(async (el, index2) => {
        if (index2 != index) {
          if (el.bom_parents_id == selectedRow.bom_parents_id) {
            const hit = await this.selected_material_list.find(
              x => x.product_unique_id == el.id,
            );

            let chkBom = this.boms.find(x => x.product_id == el.product_id);
            let notNull = true;

            if (chkBom != undefined) {
              notNull =
                chkBom.bom_process.filter(x => x.bom_resource.length != 0)
                  .length > 0
                  ? true
                  : false;
            }

            if (
              bom != undefined &&
              hit == undefined &&
              el.bom_parents_id == selectedRow.bom_parents_id &&
              notNull
            ) {
              this.pushMaterialToList(index2);
            }
          }
        }
      });
      this.groupMaterials();
    },
    deleteMaterialFromList(id) {
      // console.log('delete id', id);
      this.$store.commit(
        'setSelectedMaterialListToCalculation',
        this.lodash.clonedeep(
          this.selected_material_list.filter(x => x.bom_parents_id != id),
        ),
      );
      this.groupMaterials();
    },
    getUnitName(id) {
      let hit = this.units.find(x => x.id == id);
      return hit != undefined ? hit.name : '';
    },
    getBoxQuantity(id) {
      let hit = this.boxs.find(x => x.id == id);
      return hit != undefined ? hit.quantity : 1;
    },
    getBoxName(id) {
      let hit = this.boxs.find(x => x.id == id);
      return hit != undefined ? hit.name : '';
    },
    getCompanyInfo(id) {
      let hit = this.companys.find(x => x.id == id);
      return hit != undefined ? hit : '';
    },
    getProductInfo(id) {
      let hit = this.products.find(x => x.id == id);
      return hit != undefined ? hit : '';
    },
    getMaterialInfo(id) {
      let hit = this.materials.find(x => x.id == id);
      return hit != undefined ? hit : '';
    },
    getFirstSalesProduct(index) {
      const productCount = this.lodash
        .clonedeep(this.filteredSalesList[index].product_list)
        .filter(x => x.parent_product_id == null);
      const firstProduct = this.getProductInfo(productCount[0].product_id).name;
      return productCount.length > 1
        ? firstProduct + ' 외 ' + Number(productCount.length - 1) + '개 제품'
        : firstProduct;
    },
    getMaterialStock(id) {
      if (this.material_stock != undefined) {
        let hit = this.material_stock.find(x => x.material_id == id);
        const currentStock = hit != undefined ? Number(hit.stock_quantity) : 0;
        if (this.material_occupied_stock != undefined) {
          const hit = this.material_occupied_stock.find(
            x => x.material_id == id,
          );
          if (hit != undefined) {
            return currentStock - hit.quantity;
          } else {
            return currentStock;
          }
        } else {
          return currentStock;
        }
      } else {
        return 0;
      }
    },
    async setStartDate(e) {
      if (e.target.value != '') {
        // this.$store.commit('setStartDateToCalculation', e.target.value);
        if (e.target.value > this.end_date) {
          this.openOneButtonModal(
            '납품요청일 오류',
            `납품요청일 최후 날짜(${this.end_date}) 이후의<br>날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.start_date;
        } else {
          this.$store.commit('setStartDateToCalculation', e.target.value);
          await this.FETCH_SALES();
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        this.$store.commit('setStartDateToCalculation', yyyymmdd(new Date()));
      }
    },
    async setEndDate(e) {
      if (e.target.value != '') {
        // this.$store.commit('setEndDateToCalculation', e.target.value);
        if (e.target.value < this.start_date) {
          this.openOneButtonModal(
            '납품요청일 오류',
            `납품요청일 최초 날짜(${this.start_date}) 이전의<br>날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.end_date;
        } else {
          this.$store.commit('setEndDateToCalculation', e.target.value);
          await this.FETCH_SALES();
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        this.$store.commit('setEndDateToCalculation', yyyymmdd(new Date()));
      }
    },
    async FETCH_SALES() {
      this.showSpinner();
      await this.$store
        .dispatch('FETCH_SALES_TO_CALCULATION', {
          start_date: this.start_date,
          end_date: this.end_date,
        })
        .then(() => {
          this.$store.commit('setGroupMaterialListToCalculation', []);
          this.$store.commit('setSelectedMaterialListToCalculation', []);
          this.$store.commit('setSelectedProductListToCalculation', []);
        })
        .catch(error => {
          console.log(error);
          this.openOneButtonModal(
            '로드 중 오류',
            '수주 리스트를 불러오는 중 오류발생',
          );
        })
        .finally(() => {
          this.hideSpinner();
        });
    },
    async refreshData() {
      await this.FETCH_WITH_PAYLOAD_NO_SPINNER(
        'FETCH_SALES_TO_CALCULATION',
        {
          start_date: this.start_date,
          end_date: this.end_date,
        },
        '수주리스트',
      );
    },
    chkMaterial(product) {
      let findBomResource = this.lodash
        .clonedeep(this.boms)
        .find(x => x.product_id == product.product_id);

      if (findBomResource != undefined) {
        findBomResource = findBomResource.bom_process
          .map(x => x.bom_resource)
          .reduce((a, b) => a.concat(b));

        if (findBomResource.length == 0) {
          return true;
        }
      }
    },
  },
  async created() {
    if (this.start_date == null) {
      const today = new Date();
      today.setDate(today.getDate() + 7);
      this.$store.commit('setEndDateToCalculation', yyyymmdd(today));
      const lastMonth = new Date();
      lastMonth.setMonth(lastMonth.getMonth() - 1);
      this.$store.commit('setStartDateToCalculation', yyyymmdd(lastMonth));
    }
    if (this.products.length < 1) {
      await this.FETCH('FETCH_PRODUCT_WITH_COMPANY', '제품');
    }
    if (this.companys.length < 1) {
      await this.FETCH('FETCH_COMPANY', '거래처');
    }
    if (this.boxs.length < 1) {
      await this.FETCH('FETCH_BOX', '박스');
    }
    if (this.units.length < 1) {
      await this.FETCH('FETCH_UNIT', '단위');
    }
    if (this.boms.length < 1) {
      await this.FETCH('FETCH_BOM', 'BOM');
    }
    if (this.materials.length < 1) {
      await this.FETCH('FETCH_MATERIAL_WITH_COMPANY', '자재');
    }
    if (this.material_companys.length < 1) {
      await this.FETCH('FETCH_MATERIAL_COMPANY', '자재구매처');
    }
    if (this.unit_conversions.length < 1) {
      await this.FETCH('FETCH_UNIT_CONVERSION', '단위환산');
    }
    if (this.product_groups.length < 1) {
      await this.FETCH('FETCH_PRODUCT_GROUP', '생산 제품');
    }
    if (this.material_groups.length < 1) {
      await this.FETCH('FETCH_MATERIAL_GROUP', '그룹 자재');
    }

    await this.FETCH('FETCH_MATERIAL_TOTAL_STOCK', '자재 총재고');
    await this.FETCH('FETCH_MATERIAL_OCCUPIED_STOCK', '자재 사용중 재고');

    await this.FETCH_SALES();

    this.showSearchDate = true;
  },
};
</script>

<style scoped></style>
