<template>
  <div id="contents" class="calculation">
    <div class="contents_head">
      <h2>{{ $getPageTitle($route.path) }}</h2>
      <div class="management_btn_group">
        <div>
          <span
            class="add_favorite"
            @click="AddFavorite"
            :class="{ on: isFavorOn }"
            >즐겨찾기 추가</span
          >
          <button class="btn_admin" @click="CloseThisPage()">닫기</button>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="aside" v-if="$screen.width >= 1280">
        <ul>
          <li :class="tabIndex == 0 ? 'active' : ''">
            <a @click="SetOpenTabIndex(0)">원자재 소요량 산출</a>
          </li>
        </ul>
      </div>
      <calculation-form v-if="tabIndex == 0"></calculation-form>
    </div>
  </div>
</template>

<script>
import ClosePageMixin from '@/mixins/closePage';
import FavoriteMixin from '@/mixins/favorite';
import CalculationForm from '@/views/forms/Plan/CalculationForm.vue';
import { mapGetters, mapMutations } from 'vuex';
export default {
  mixins: [ClosePageMixin, FavoriteMixin],
  components: {
    CalculationForm,
  },
  computed: {
    ...mapGetters({
      managementMode: 'getManagementModeFromCalculationPage',
      tabIndex: 'getOpenTabIndexFromCalculationPage',
    }),
  },
  methods: {
    ...mapMutations({
      ToggleManagementMode: 'toggleManagementModeToCalculationPage',
      SetOpenTabIndex: 'setOpenTabIndexToCalculationPage',
    }),
  },
  async created() {
    if (this.$route.meta.menu_init == true) {
      this.$store.commit('InitCalculationPage');
      this.$route.meta.menu_init = false;
    }
  },
};
</script>

<style scoped></style>
