var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"article"},[_c('div',{staticClass:"step1"},[_vm._m(0),_c('div',{staticClass:"cont"},[_c('span',[_vm._v("납품 요청일")]),(_vm.showSearchDate)?_c('div',{staticClass:"input_text"},[(_vm.showSearchDate)?_c('input',{attrs:{"type":"date","placeholder":"YYYY-MM-DD"},domProps:{"value":_vm.start_date},on:{"change":function($event){return _vm.setStartDate($event)}}}):_vm._e(),_vm._v("~ ")]):_vm._e(),(_vm.showSearchDate)?_c('div',{staticClass:"input_text"},[(_vm.showSearchDate)?_c('input',{attrs:{"type":"date","placeholder":"YYYY-MM-DD"},domProps:{"value":_vm.end_date},on:{"change":function($event){return _vm.setEndDate($event)}}}):_vm._e()]):_vm._e(),_c('button',{staticClass:"btn_sub1 refresh_btn",on:{"click":_vm.refreshData}},[_vm._v(" 재조회 ")])])]),_c('div',{staticClass:"step2"},[_vm._m(1),_c('div',{staticClass:"cont"},[_c('h6',[_vm._v(" 조회수 : "+_vm._s(_vm.filteredSalesList.length)+"건 ")]),_c('div',{staticClass:"mes_tbl_wrap"},[_c('table',{staticClass:"mes_tbl"},[_c('colgroup',_vm._l((4),function(n,index){return _c('col',{key:index})}),0),_vm._m(2),_c('tbody',_vm._l((_vm.filteredSalesList),function(sale,index){return _c('tr',{key:sale.id},[_c('td',[_vm._v(_vm._s(_vm.getCompanyInfo(sale.company_id).name || ''))]),_c('td',[_vm._v(_vm._s(_vm.getFirstSalesProduct(index)))]),_c('td',[_vm._v(_vm._s(sale.delivery_date))]),_c('td',[_c('button',{staticClass:"btn_tbl",class:{
                    disabled:
                      _vm.selected_product_list.find(
                        function (x) { return x.sales_id == sale.id; }
                      ) != undefined,
                  },on:{"click":function($event){_vm.selected_product_list.find(function (x) { return x.sales_id == sale.id; }) ==
                    undefined
                      ? _vm.pushProductsToList(index)
                      : _vm.deleteProductsFromList(sale.id)}}},[_vm._v(" "+_vm._s(_vm.selected_product_list.find(function (x) { return x.sales_id == sale.id; }) != undefined ? '추출값 반환' : '제품 추출')+" ")])])])}),0)])])])]),_c('div',{staticClass:"step3"},[_vm._m(3),_c('div',{staticClass:"cont"},[_c('div',{staticClass:"mes_tbl_wrap"},[_c('table',{staticClass:"mes_tbl"},[_c('colgroup',_vm._l((5),function(n,index){return _c('col',{key:index})}),0),_vm._m(4),_c('tbody',_vm._l((_vm.selected_product_list),function(product,index){return _c('tr',{key:product.id},[_c('td',{staticClass:"text_left"},[_vm._v(" "+_vm._s(_vm.getCompanyInfo( _vm.sales.find(function (x) { return x.id == product.sales_id; }).company_id ).name)+" ")]),_c('td',{staticClass:"text_left"},[_vm._v(" "+_vm._s(product.parent_product_id != null ? _vm.getProductInfo(product.parent_product_id).name + ' ▶ ' : '')+" "+_vm._s(product.product_label != undefined ? product.product_label : _vm.getProductInfo(product.product_id).name)+" ")]),_c('td',{staticClass:"text_left"},[_vm._v(" "+_vm._s(_vm.getProductInfo(product.product_id).standard)+" ")]),(product.quantity_label != undefined)?_c('td',{staticClass:"text_right"},[_c('b',[_vm._v(_vm._s(product.quantity_label.split(' ')[0]))]),_vm._v(" "+_vm._s(product.quantity_label.split(' ')[1])+" ")]):_c('td',{staticClass:"text_right"},[_vm._v(" "+_vm._s(product.box_unit_id == null ? "" : ((_vm.$makeComma(product.quantity)) + " x " + (_vm.getBoxName( product.box_unit_id )) + " = "))+" "),_c('b',[_vm._v(_vm._s(product.box_unit_id == null ? ("" + (_vm.$makeComma(product.quantity))) : ("" + (_vm.$makeComma( _vm.$decimalMul( product.quantity, _vm.getBoxQuantity(product.box_unit_id) ) )))))]),_vm._v(" "+_vm._s(("(" + (_vm.getUnitName(product.unit_id)) + ")"))+" ")]),_c('td',[_c('button',{staticClass:"btn_tbl",class:{
                    disabled:
                      _vm.chkMaterial(product) ||
                      (_vm.selected_material_list.find(
                        function (x) { return x.bom_parents_id == product.bom_parents_id; }
                      ) != undefined &&
                        (_vm.boms.find(function (x) { return x.product_id == product.product_id; }) !=
                          undefined ||
                          _vm.product_groups.filter(
                            function (x) { return x.member_product_id == product.product_id; }
                          ).length > 0)),
                  },attrs:{"disabled":_vm.chkMaterial(product)},on:{"click":function($event){_vm.selected_material_list.find(
                      function (x) { return x.bom_parents_id == product.bom_parents_id; }
                    ) != undefined
                      ? _vm.boms.find(function (x) { return x.product_id == product.product_id; }) ==
                          undefined &&
                        _vm.product_groups.filter(
                          function (x) { return x.member_product_id == product.product_id; }
                        ).length == 0
                        ? _vm.pushMaterialToList(index)
                        : _vm.deleteMaterialFromList(product.bom_parents_id)
                      : _vm.pushMaterialToList(index)}}},[_vm._v(" "+_vm._s(_vm.chkMaterial(product) ? '자재 추출' : _vm.selected_material_list.find( function (x) { return x.bom_parents_id == product.bom_parents_id; } ) != undefined ? _vm.boms.find(function (x) { return x.product_id == product.product_id; }) == undefined && _vm.product_groups.filter( function (x) { return x.member_product_id == product.product_id; } ).length == 0 ? '자재 추출' : '추출값 반환' : '자재 추출')+" ")])])])}),0)])])])]),_c('div',{staticClass:"step4"},[_vm._m(5),_c('div',{staticClass:"cont"},[_c('div',{staticClass:"mes_tbl_wrap"},[_c('table',{staticClass:"mes_tbl"},[_c('colgroup',{class:{ group_material_chk: _vm.isIncludeGroupMaterialOnList }},_vm._l((7),function(n,index){return _c('col',{key:index})}),0),_vm._m(6),_c('tbody',_vm._l((_vm.group_material_list),function(material,index){return _c('tr',{key:index},[(!material.member_yn)?_c('td',{staticClass:"text_left",class:{
                  group_td:
                    _vm.isIncludeGroupMaterialOnList &&
                    _vm.findInfoFromId(_vm.materials, material.material_id)
                      .material_group_yn == 1,
                },attrs:{"colspan":_vm.isIncludeGroupMaterialOnList &&
                  _vm.findInfoFromId(_vm.materials, material.material_id)
                    .material_group_yn
                    ? 1
                    : 2,"rowspan":_vm.group_material_list.filter(
                    function (x) { return x.parents_id == material.material_id; }
                  ).length + 1}},[_vm._v(" "+_vm._s(_vm.getMaterialInfo(material.material_id).name)),_c('br',{directives:[{name:"show",rawName:"v-show",value:(
                    _vm.findInfoFromId(_vm.materials, material.material_id)
                      .material_group_yn
                  ),expression:"\n                    findInfoFromId(materials, material.material_id)\n                      .material_group_yn\n                  "}]}),_vm._v(_vm._s((_vm.getMaterialInfo(material.material_id).standard.trim() .length == 0 ? '' : ' - ') + _vm.getMaterialInfo(material.material_id).standard)+" ")]):_vm._e(),(
                  _vm.isIncludeGroupMaterialOnList &&
                    (_vm.findInfoFromId(_vm.materials, material.material_id)
                      .material_group_yn == true ||
                      material.member_yn == true)
                )?_c('td',{staticClass:"group_option"},[_vm._v(" "+_vm._s(material.member_yn ? _vm.findInfoFromId(_vm.materials, material.material_id).name + (_vm.findInfoFromId( _vm.materials, material.material_id ).standard.trim().length == 0 ? '' : ' - ') + _vm.findInfoFromId(_vm.materials, material.material_id).standard : '')+" "),(
                    _vm.findInfoFromId(_vm.materials, material.material_id)
                      .material_group_yn && _vm.isPermissionOn
                  )?_c('button',{on:{"click":function($event){_vm.include_list = _vm.findInfoFromId(
                      _vm.materials,
                      material.material_id
                    ).material_group_list;
                    _vm.ignore_list = _vm.group_material_list
                      .filter(function (x) { return x.parents_id == material.material_id; })
                      .map(function (x) {
                        return {
                          id: x.material_id,
                          resource_type_id: _vm.findInfoFromId(
                            _vm.materials,
                            x.material_id
                          ).resource_type_id,
                        };
                      });
                    _vm.selected_group_material = material;
                    _vm.selected_group_material_index = index;
                    _vm.showMaterialSearch = true;}}},[_c('img',{attrs:{"src":require("@/assets/images/icon/icon-floating-search.png")}})]):_vm._e(),(material.member_yn)?_c('button',{staticClass:"floating_delete",on:{"click":function($event){return _vm.group_material_list.splice(index, 1)}}},[_c('img',{attrs:{"src":require("@/assets/images/icon/icon-floating-delete.png")}})]):_vm._e()]):_vm._e(),(
                  !_vm.findInfoFromId(_vm.materials, material.material_id)
                    .material_group_yn && _vm.isPermissionOn
                )?_c('td',{staticClass:"text_left",class:{
                  not_group:
                    !_vm.findInfoFromId(_vm.materials, material.material_id)
                      .material_group_yn &&
                    material.order_company == -1 &&
                    _vm.$makeNumber(material.order_quantity) > 0,
                }},[_vm._v(" "+_vm._s(_vm.getCompanyInfo(material.order_company).name)),_c('span',{staticClass:"ico_search",on:{"click":function($event){{
                      _vm.ignore_company_list = [];
                      if (material.material_companys.length < 1) {
                        _vm.ignore_company_list = undefined;
                      } else {
                        material.material_companys.forEach(function (el) {
                          _vm.ignore_company_list.push(el.company_id);
                        });
                      }
                      _vm.clicked_index = index;
                      _vm.showCompanySearch = true;
                    }}}})]):_c('td'),_c('td',[_vm._v(" "+_vm._s(("" + (_vm.getUnitName( _vm.getMaterialInfo(material.material_id).using_unit_id ))))+" ")]),(!material.member_yn)?_c('td',{staticClass:"text_right"},[_c('b',[_vm._v(_vm._s(("" + (_vm.$makeComma(material.need_quantity)))))]),_vm._v(" "+_vm._s(("(" + (_vm.getUnitName( _vm.getMaterialInfo(material.material_id).using_unit_id )) + ")"))+" ")]):_c('td',{staticClass:"text_right"},[_c('b',[_vm._v(_vm._s(("" + (_vm.$makeComma(_vm.getGroupMaterialNeedQuantity(material))))))]),_vm._v(" "+_vm._s(("(" + (_vm.getUnitName( _vm.getMaterialInfo(material.material_id).using_unit_id )) + ")"))+" ")]),(
                  !_vm.findInfoFromId(_vm.materials, material.material_id)
                    .material_group_yn
                )?_c('td',{staticClass:"text_right"},[_c('b',[_vm._v(" "+_vm._s(("" + (_vm.$makeComma(_vm.getMaterialStock(material.material_id)))))+" ")]),_vm._v(_vm._s((" (" + (_vm.getUnitName( _vm.getMaterialInfo(material.material_id).incoming_unit_id )) + ")"))+" ")]):_c('td',{staticClass:"text_right"},[_c('b',[_vm._v(" "+_vm._s(("" + (_vm.$makeComma( _vm.group_material_list .filter(function (x) { return x.parents_id == material.material_id; }) .map(function (x) { return _vm.getMaterialStock(x.material_id); }) .reduce(function (a, b) { return _vm.$decimalAdd(a, b); }, 0) ))))+" ")]),_vm._v(_vm._s((" (" + (_vm.getUnitName( _vm.getMaterialInfo(material.material_id).incoming_unit_id )) + ")"))+" ")]),(
                  !_vm.findInfoFromId(_vm.materials, material.material_id)
                    .material_group_yn
                )?_c('td',[_c('input',{attrs:{"type":"text","placeholder":"수량 입력","inputmode":"decimal","disabled":!_vm.isPermissionOn},domProps:{"value":_vm.$makeComma(material.order_quantity)},on:{"input":function($event){return _vm.$inputNumber($event, material, 'order_quantity')}}}),_c('label',[_vm._v(" "+_vm._s((" " + (_vm.getUnitName( _vm.getMaterialInfo(material.material_id).incoming_unit_id )))))])]):_c('td')])}),0)])])])]),_c('div',{staticClass:"btn_wrap"},[(_vm.isPermissionOn)?_c('button',{staticClass:"btn_sub2",attrs:{"disabled":_vm.isValidModify},on:{"click":_vm.checkModal}},[_vm._v(" 원자재 발주 ")]):_vm._e()]),(_vm.showCompanySearch)?_c('company-search',{attrs:{"search_type":2,"ignore_company_list":_vm.ignore_company_list},on:{"onclose":function($event){_vm.showCompanySearch = false},"onselect":function($event){return _vm.chageOrderCompany($event)}}}):_vm._e(),(_vm.showMaterialSearch)?_c('resource-search',{attrs:{"filter_type":1,"include_list":_vm.filtered_include_list,"ignore_list":_vm.ignore_list},on:{"onclose":function($event){_vm.showMaterialSearch = false},"onselect":function ($event) {
        _vm.insertMemberMaterial($event);
        _vm.showMaterialSearch = false;
      }}}):_vm._e(),_c('two-button-modal',{attrs:{"modal_title":_vm.my_modal_title,"modal_content":_vm.my_modal_content,"isModalOpen":_vm.my_isModalOpen,"index":-1},on:{"onclose":function($event){_vm.my_isModalOpen = false},"OnYesClick":function () {
        _vm.goToBom();
        _vm.my_isModalOpen = false;
      }}}),_c('two-button-modal2',{attrs:{"modal_title":'발주량 부족',"modal_content":_vm.warning_text,"isModalOpen":_vm.warning_show},on:{"onclose":function($event){_vm.warning_show = false},"OnYesClick":function () {
        _vm.submitForm();
        _vm.warning_show = false;
      }}}),_c('div',{staticClass:"modal+_"})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"title"},[_c('h5',[_c('span',[_vm._v("1")]),_vm._v("수주내역 검색")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"title"},[_c('h5',[_c('span',[_vm._v("2")]),_vm._v("수주 항목 내 세부제품 추출")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',[_vm._v("매출처")]),_c('th',[_vm._v("납품제품")]),_c('th',[_vm._v("납품 요청일")]),_c('th',[_vm._v("전체 제품 추출")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"title"},[_c('h5',[_c('span',[_vm._v("3")]),_vm._v("세부제품 내 필요 원자재 추출")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',[_vm._v("매출처")]),_c('th',[_vm._v("제품명")]),_c('th',[_vm._v("규격")]),_c('th',[_vm._v("수량(단위)")]),_c('th',[_vm._v("필요 원자재 추출")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"title"},[_c('h5',[_c('span',[_vm._v("4")]),_vm._v("필요 원자재 발주")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',{attrs:{"colspan":"2"}},[_vm._v(" 원자재명 ")]),_c('th',[_vm._v("구매처")]),_c('th',[_vm._v("사용단위")]),_c('th',[_vm._v("필요수량")]),_c('th',[_vm._v("재고수량")]),_c('th',[_vm._v("필요 발주량 (안전재고+필요수량)")])])])}]

export { render, staticRenderFns }